<template>
  <header>
    <div class="header-main">
      <div class="logo" @click="toHome">
        <img src="https://images.zzt.com.cn/ns/2023/06/21/zzt_logo.png" alt="logo" />
      </div>
      <nav v-if="$route.meta.type === 'default'">
        <div v-for="item in navList" class="nav-item" :key="item.path" @click="toPage(item.path)"
          :class="{ active: JSON.stringify(item).includes(currentPath) }">
          <template v-if="item.isSelect">
            <el-popover placement="bottom" width="170" trigger="hover" popper-class="custom-my-popover"
              v-model="isLaunch">
              <div class="select-main">
                <div class="select-item" v-for="its in item.children" :key="its.path"
                  :class="{ 'active-item': its.path === currentPath }" @click="toPage(its.path)">
                  {{ its.label }}
                </div>
              </div>
              <div slot="reference">
                <p class="text">{{ item.label }}
                  <i :class="isLaunch ? 'el-icon-caret-top' : 'el-icon-caret-bottom'"></i>
                </p>
                <div class="bg"></div>
              </div>
            </el-popover>
          </template>
          <template v-else>
            <p class="text">{{ item.label }}</p>
            <div class="bg"></div>
          </template>
        </div>
      </nav>
      <div class="log-status" v-if="$route.meta.type === 'default'">
        <div class="unlog" v-if="!isLogin" style="margin-bottom: 2px;">
          <div class="log-on try" @click="login">登录</div>
          <!-- <router-link class="try" to="/register?redirect=${redirect}`">申请试用</router-link> -->
        </div>
        <div class="loged" v-else>
          <p class="product-center" @click="goToProductCenter" v-if="$route.meta.type === 'default'">
            产品中心
          </p>
          <div class="log-out">
            <el-popover placement="bottom" width="320" height="638" trigger="hover" popper-class="custom_popover"
              @show="updateInfo">
              <div slot="reference" style="display: flex;align-items: center;">
                <el-avatar :size="36" src="https://images.zzt.com.cn/ks/2023/05/11/avatar.png"></el-avatar>
                <div :class="{ 'userName': true, 'haveNotices': isShowMsgTip }">
                  {{ userInfo.nickname }}
                </div>
                <i class="el-icon-caret-bottom log-out"></i>
              </div>
              <div class="scroll_content">
                <div class="account_center">
                  <div class="avatar">
                    <img src="https://images.zzt.com.cn/ks/2023/05/11/avatar.png">
                    <div class="nick_name">
                      <div>{{ userInfo.nickname }}</div>
                      <div>{{ userInfo.accountType == 1 ? '主账号' : '子账号' }}</div>
                    </div>
                  </div>
                  <div class="link" @click="$router.push('/accountCenter')">
                    账号中心
                    <i class="el-icon-arrow-right"></i>
                  </div>
                </div>
                <div class="common_functions">
                  <div class="title">常用功能</div>
                  <div class="content_box">
                    <span class="my_list" @click="$router.push('/accountCenter/myList')">
                      <!-- <i class="el-icon-document"></i> -->
                      <i class="iconfont icon-wodemingdan"></i>
                      我的名单
                    </span>
                    <span :class="{ 'my_list': true, 'haveNotices': isShowMsgTip }" @click="clickMsgCenter"
                      style="margin-left: 30px;">
                      <!-- <i class="el-icon-bell"></i> -->
                      <i class="iconfont icon-xiaoxizhongxin"></i>
                      消息中心
                    </span>
                  </div>
                </div>
                <div class="my_product">
                  <div class="title">权限详情</div>
                  <div class="list">
                    <div class="pate"
                      v-if="equityInformation.pateProductRightsList && equityInformation.pateProductRightsList.length > 0">
                      <div class="pate_title">深眸</div>
                      <div class="pate_authority">
                        <div class="item" v-for="(item, index) in equityInformation.pateProductRightsList" :key="index">
                          <div class="left_title">{{ item.rightsTypeName }}{{
                            item.rightsLevelName ? `(${item.rightsLevelName})` : '' }}</div>
                          <div class="right_day">
                            <template v-if="item.ifOverdue">
                              <div @click="openDialog">
                                已过期，<span class="link_span">联系客服</span>
                              </div>
                            </template>
                            <template v-else>
                              剩余
                              <span>
                                {{ translateText(item.remainDays) }}
                              </span>
                              天
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="blue_book"
                      v-if="equityInformation.littleBlueBookProductRightsList && equityInformation.littleBlueBookProductRightsList.length > 0">
                      <div class="blue_book_title">小蓝书</div>
                      <div class="blue_book_authority">
                        <div class="item" v-for="(item, index) in equityInformation.littleBlueBookProductRightsList"
                          :key="index">
                          <div class="item_title">
                            <div class="title_text">
                              {{ item.rightsTypeName }}{{ item.rightsLevelName ? `(${item.rightsLevelName})` : '' }}
                            </div>
                          </div>
                          <div class="day">
                            <div class="left">权限时间:</div>
                            <div class="right">
                              <template v-if="item.ifOverdue">
                                <!-- 过期 -->
                                <div @click="openDialog">
                                  已过期，<span class="link_span">联系客服</span>
                                </div>
                              </template>
                              <template v-else>
                                剩余<span>{{ translateText(item.remainDays) }}</span>天
                              </template>
                            </div>
                          </div>
                          <div class="preview">
                            <div class="left">预览次数:</div>
                            <div class="right">
                              <template v-if="item.rightsLevelCode === 2">
                                <!-- 付费 -->
                                剩余<span>{{ item.remainSearchTimes === -1 ? '无限' : item.remainSearchTimes }}</span>次
                              </template>
                              <template v-else>
                                <!-- 体验 注册 -->
                                剩余<span>{{ translateText(item.remainSearchTimes) }}</span>次/天
                              </template>
                            </div>
                          </div>
                          <div class="download">
                            <div class="left">下载次数:</div>
                            <div class="right">剩余<span>{{ translateText(item.remainDownloadTimes) }}</span>次</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="logout">
                  <div class="logout_btn" @click="handleCommand('logout')">退出登录</div>
                </div>
              </div>
            </el-popover>
          </div>
        </div>
      </div>
    </div>
    <customerServiceDialog ref="customerServiceRef" />
  </header>
</template>

<script>
import { mapState } from 'vuex'
import customerServiceDialog from "@/components/customerServiceDialog.vue";
// import { useLogin } from '@/hooks'
import * as api from '@/api'
export default {
  components: {
    customerServiceDialog
  },
  data() {
    return {
      isLaunch: false,
      currentPath: '/home',
      navList: [
        // { label: '首页', path: '/home' },
        // { label: '深眸', path: '/patE' },
        // { label: '深蓝', path: '/deepBlue' },
        // { label: '小蓝书', path: '/blueReport' },
        // // { label: 'OpenAPI', path: '/OpenAPI' },
        // { label: '中知智库', path: '/thinkTanks' },
        // { label: '公司动态', path: '/aboutUs' }
        { label: '科技金融服务', path: '/financialService' },
        {
          label: '产品与数据',
          isSelect: true,
          children: [
            { label: '深眸', path: '/patE', },
            { label: '瞰见', path: '/aerialView', },
            { label: '专利导航', path: '/patentNavigation', },
            { label: '数据服务平台', path: '/bigData' },
          ]
        },
        { label: '关于中知数通', path: '/aboutUs' }
      ],
      pateAuthorityList: [
        {
          label: '深眸PC版',
          value: '40',
        }, {
          label: '深眸小程序标准版（体验）',
          value: '400',
        }, {
          label: '深眸小程序银行版（会员）',
          value: '120',
        }
      ],
      bookAuthorityList: [
        {
          name: '专利价值评估报告（注册）',
          remainingTime: '20',
          previewTimes: '3',
          numberOfDownloads: '30',
        }, {
          name: '科技尽调报告（付费）',
          remainingTime: '90',
          previewTimes: '50',
          numberOfDownloads: '122',
        }
      ],
      isShowMsgTip: false,
    }
  },
  computed: {
    ...mapState(['isLogin', 'userInfo', 'equityInformation'])
  },
  methods: {
    toHome() {
      // if (!['Login', 'Register', 'Home'].includes(this.$route.name)) {
      this.toPage('/home')
      // }
    },
    toPage(path) {
      this.$router.push(path)
      window.scrollTo(0, 0, 'smooth')
    },
    login() {
      // const { toLoginPage } = useLogin(this)
      // toLoginPage()
      this.$router.push('/login')
    },
    goToProductCenter() {
      this.$router.push('/productcenter')
    },
    handleCommand(command) {
      console.log('执行退出')
      if (command === 'logout') {
        this.$store.dispatch('logOut')
        setTimeout(() => {
          this.$router.push('/login')
        }, 300);
      }
    },
    openDialog() {
      this.$refs.customerServiceRef.showDialog(true)
    },
    translateText(val) {
      return this.equityInformation.ifWhitelist ? '无限' : Number(val) === -1 ? '无限' : val
    },
    updateInfo() {
      this.checkMonitorNoticeUser()
      this.$store.dispatch('updatePersonalRights')
    },
    clickMsgCenter() {
      this.$router.push('/accountCenter/msgCenter')
      this.deleteMonitorNoticeUser()
    },
    //查询用户是否进行站内信消息提醒
    checkMonitorNoticeUser() {
      api.riskMonitor.checkMonitorNoticeUser().then(({ data, success }) => {
        if (success) {
          this.isShowMsgTip = data.data
        }
      })
    },
    deleteMonitorNoticeUser() {
      api.riskMonitor.deleteMonitorNoticeUser().then(({ success }) => {
        if (success) {
          this.isShowMsgTip = false
        }
      })
    }
  },
  watch: {
    $route(to) {
      this.currentPath = to.path
    }
  },
  mounted() {
    const { path } = this.$router.currentRoute
    this.currentPath = path
    this.checkMonitorNoticeUser()
  }
}
</script>

<style lang="scss" scoped>
header {
  background-color: #fff;
  border-bottom: 1px solid #eee7e7;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;

  .header-main {
    height: $header-height;
    width: $container-width;
    margin: 0 auto;
    z-index: 5;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      display: flex;
      align-items: center;
      cursor: pointer;

      .split-icon {
        padding: 0 28px;
        color: #dfdfdf;
      }

      img {
        // width: 740px;
        width: 620px;
      }
    }

    nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: inherit;
      margin-left: Translate(44px);

      .nav-item {
        color: #333;
        font-size: 36px;
        // margin: 0 0 0 Translate(44px);
        margin: 0 Translate(44px) 0 0;
        height: inherit;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: Translate(16px);
        align-items: center;

        &.active {
          .text {
            color: #4088eb;
            font-weight: 500;
            font-size: Translate(16px);
          }

          .bg {
            width: Translate(80px);
            height: Translate(2px);
            background-color: #4088eb;
            position: absolute;
            bottom: Translate(0px);
          }
        }

        &:hover {
          .text {
            color: #4088eb;
            font-weight: 500;
            font-size: Translate(16px);
          }
        }
      }
    }

    .product-center {
      width: Translate(88px);
      height: Translate(32px);
      line-height: Translate(32px);
      border-radius: Translate(4px);
      background: #4088eb;
      font-size: Translate(14px);
      color: #ffffff;
      text-align: center;
      font-weight: 400;
      cursor: pointer;
      margin-right: Translate(20px);

      &:hover {
        background-color: #337ee5;
      }
    }

    .unlog {
      display: flex;
      justify-content: center;
      align-items: center;

      .log-on {
        color: #4088eb;
        font-size: Translate(15px);
        text-align: center;
        cursor: pointer;
        font-weight: 400;
        margin: 0 0 0 Translate(20px);
      }

      .try {
        width: Translate(104px);
        height: Translate(36px);
        padding: Translate(7px) Translate(20px);
        box-sizing: border-box;
        background-color: #4088eb;
        color: white;
        border: none;
        font-size: Translate(15px);
        border-radius: Translate(4px);
        cursor: pointer;

        &:hover {
          background: #337ee5;
        }
      }
    }
  }

  .loged {
    display: flex;
    align-items: center;

    .userName {
      max-width: Translate(100px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: Translate(8px);
      font-size: Translate(14px);
      color: #333;
      // line-height: Translate(14px);
      font-weight: 400;
    }

    .log-out {
      margin-left: Translate(10px);
      cursor: pointer;
      color: #999999;
    }
  }
}

::v-deep .log-out .el-dropdown {
  position: relative;

  .el-icon-caret-bottom {
    position: relative;
    left: -10px;
  }
}

.scroll_content {
  max-height: 90vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

.account_center {
  width: 100%;
  height: Translate(50px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: Translate(16px);

  .avatar {
    width: Translate(102px);
    display: flex;
    align-items: center;
    flex: 1;

    img {
      width: Translate(50px);
      height: Translate(50px);
    }

    .nick_name {
      margin-left: Translate(10px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      div {
        &:nth-child(1) {
          width: Translate(130px);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: Translate(16px);
          color: #333;
          font-weight: bold;
        }

        &:nth-child(2) {
          font-size: Translate(14px);
          color: #999999;
          margin-top: Translate(2px);
        }
      }
    }
  }

  .link {
    flex: 1;
    font-size: Translate(14px);
    color: #4088eb;
    cursor: pointer;
    text-align: right;
  }
}

.common_functions {
  width: 100%;
  padding: Translate(12px) 0px Translate(10px) 0px;
  border-bottom: 1px solid #f1f1f1;

  .content_box {
    margin-top: Translate(10px);
    display: flex;
    justify-content: flex-start;

    .my_list {
      font-size: Translate(14px);
      cursor: default;

      &:hover {
        color: #4088eb;
      }
    }
  }

  .title {
    font-size: Translate(14px);
    color: #999999;

    &::before {
      content: '';
      display: inline-block;
      width: Translate(3px);
      height: Translate(12px);
      background: #999999;
      margin-right: Translate(5px);
    }
  }

  .list {
    font-size: Translate(12px);
    color: #333333;
    display: flex;
    justify-content: space-around;
    margin-top: Translate(10px);

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        margin-top: Translate(8px);
      }
    }
  }
}

.my_product {
  width: 100%;
  padding: Translate(10px) 0px 0px 0px;

  // border-bottom: 1px solid #f1f1f1;
  .title {
    font-size: Translate(14px);
    color: #999999;

    &::before {
      content: '';
      display: inline-block;
      width: Translate(3px);
      height: Translate(12px);
      background: #999999;
      margin-right: Translate(5px);
    }
  }

  .list {
    .pate {
      margin-top: Translate(10px);
      background: #4088eb;
      border-top-left-radius: Translate(8px);
      border-top-right-radius: Translate(8px);
      border-bottom-left-radius: Translate(20px);
      border-bottom-right-radius: Translate(20px);
      font-weight: 400;

      &_title {
        width: calc(100% - Translate(28px));
        height: Translate(32px);
        font-size: Translate(14px);
        color: #ffffff;
        padding: 0 Translate(14px) 0 Translate(14px);
        line-height: Translate(32px);
      }

      .pate_authority {
        background: #f5f8fd;
        border-radius: Translate(8px);
        padding: Translate(15px) Translate(14px) Translate(20px) Translate(14px);
        width: calc(100% - Translate(28px));

        .item {
          display: flex;
          justify-content: space-between;
          height: Translate(28px);
          line-height: Translate(28px);

          .left_title {
            color: #333333;
          }

          .right_day {
            color: #999999;

            span {
              color: #ff8321;
            }

            .link_span {
              color: #4088eb;
              cursor: pointer;
              text-decoration: underline;
            }
          }
        }
      }
    }

    .blue_book {
      margin-top: Translate(16px);
      background: #12c39b;
      border-top-left-radius: Translate(8px);
      border-top-right-radius: Translate(8px);
      border-bottom-left-radius: Translate(20px);
      border-bottom-right-radius: Translate(20px);
      font-weight: 400;

      &_title {
        width: calc(100% - Translate(28px));
        height: Translate(60px);
        font-size: Translate(14px);
        color: #ffffff;
        padding: 0 Translate(14px) 0 Translate(14px);
        line-height: Translate(32px);
        height: Translate(32px);
      }

      .blue_book_authority {
        background: #f2fbf9;
        border-radius: Translate(8px);
        padding: Translate(4px) Translate(14px) Translate(20px) Translate(14px);
        width: calc(100% - Translate(28px));

        .item {
          color: #999999;

          .item_title {
            display: flex;
            justify-content: center;
            width: 100%;
            margin-bottom: Translate(8px);
            margin-top: Translate(15px);

            .title_text {
              font-size: Translate(14px);
              color: #333333;
              text-align: center;
              font-weight: bold;

              &::before,
              &::after {
                content: '';
                display: inline-block;
                width: Translate(28px);
                height: Translate(1px);
                background: #888888;
                position: relative;
                top: Translate(-6px);
                margin: 0px Translate(10px);
              }
            }
          }

          .day,
          .preview,
          .download {
            display: flex;
            justify-content: space-between;
            height: Translate(28px);
            line-height: Translate(28px);

            span {
              color: #ff8321;
            }

            .link_span {
              color: #4088eb;
              cursor: pointer;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

.logout {
  display: flex;
  justify-content: center;
  margin-top: Translate(13px);

  .logout_btn {
    width: Translate(120px);
    height: Translate(34px);
    background: #f5f6f9;
    border-radius: Translate(17px);
    text-align: center;
    line-height: Translate(34px);
    cursor: pointer;
  }
}
</style>
<style lang="scss">
.custom_popover {
  padding: Translate(16px) Translate(24px) Translate(13px) Translate(24px);
}

.custom-my-popover {
  box-shadow: none;
  border: 0px;
  padding: 0px;

  .select-main {
    margin-top: Translate(20px);

    .select-item {
      text-align: center;
      height: Translate(44px);
      line-height: Translate(44px);
      cursor: pointer;
      font-size: Translate(14px);
      color: #333333;

      &:hover {
        background: #F3F9FF;
        color: #4088EB;
        font-weight: 500;
      }
    }

    .active-item {
      background: #F3F9FF;
      color: #4088EB;
      font-weight: 500;
    }
  }

  .popper__arrow {
    display: none;
  }
}

.haveNotices {
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    width: Translate(6px);
    height: Translate(6px);
    background-color: #ff1f00;
    border-radius: Translate(3px);
  }
}

.first-level-main {
  display: flex;
  align-items: center;

  .first-level-text {
    font-size: Translate(16px);
    color: #333333;
    font-weight: 500;
    margin-left: Translate(28px);
  }
}
.second-level-main{
  text-align: left;
  padding-left: Translate(10px);
  font-size: Translate(14px);
}
</style>